<template>
  <section
    class="blogContent"
    v-for="(singleblogs, key) in SingleBlog"
    :key="key"
  >
    <div class="container blogs-container">
      <div class="row">
        <div class="col-md-12 social-share">
          <div>
            <span>{{ singleblogs.date }}</span>
            <span class="dot"> . </span>
            <span class="readtime">5 min read</span>
          </div>
          <div class="blogssocial-links">
            <a href="" target="blank" class="twitter">
              <ICON_Twitter
                :size="icon_size"
                :color="social_icon"
                class="vicon"
              />
            </a>
            <a href="" target="blank" class="facebook">
              <ICON_Facebook
                :size="icon_size"
                :color="social_icon"
                class="vicon"
              />
            </a>
            <a href="" target="_blank">
              <ICON_Linkedin
                :size="icon_size"
                :color="social_icon"
                class="vicon"
              />
            </a>
            <a href="" target="_blank">
              <ICON_Mail
                :size="icon_size"
                :color="social_icon"
                class="vicon"
              />
            </a>
            <a href="" target="_blank">
              <ICON_Share
                :size="icon_size"
                :color="social_icon"
                class="vicon"
              />
            </a>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <h1 class="mb-2">{{ singleblogs.title }}</h1>
          <div class="sub-title">{{ singleblogs.subtitle }}</div>
        </div>
        <div class="col-md-12 blog-image">
          <Image :fpath="`${singleblogs.cover}.jpg`" class="serviceimage" />
        </div>
        <div class="col-md-12 pb-2">
          <p
            v-html="$filters.markdown(singleblogs.content)"
            class="blog-content"
          ></p>
        </div>
        <hr />
        <div class="row v-items-center">
          <div class="col-md-4">
            <h5>Share this post</h5>
            <div class="blogssocial-links">
              <a href="" target="blank" class="twitter">
                <ICON_Twitter
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="blank" class="facebook">
                <ICON_Facebook
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="_blank">
                <ICON_Linkedin
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="_blank">
                <ICON_Mail
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
              <a href="" target="_blank">
                <ICON_Share
                  :size="icon_size"
                  :color="social_icon"
                  class="vicon"
                />
              </a>
            </div>
          </div>
          <div class="col-md-8">
            <div class="d-flex blogtags">
              <ICON_Tags :size="tag_size" :color="social_icon" />
              <span style="padding: 0px 10px">{{ singleblogs.tag_line }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <RecentPosts />
</template>

<script>
import RecentPosts from "@/components/Blogposts/RecentPosts.vue";
import Image from "@/components/Image.vue";
import axios from "axios";
import ICON_Facebook from "@/assets/icons/Facebook.vue";
import ICON_Twitter from "@/assets/icons/Twitter.vue";
import ICON_Linkedin from "@/assets/icons/Linkedin.vue";
import ICON_Tags from "@/assets/icons/Tags.vue";
import ICON_Mail from "@/assets/icons/Mail.vue";
import ICON_Share from "@/assets/icons/Share.vue";
export default {
  components: {
    RecentPosts,
    Image,
    ICON_Facebook,
    ICON_Twitter,
    ICON_Linkedin,
    ICON_Tags,
    ICON_Mail,
    ICON_Share,
  },
  data() {
    return {
      social_icon: "rgba(168, 168, 168, 1)",
      icon_size: 24,
      tag_size: 18,
      lang: "en",
      SingleBlog: [],
    };
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/blogs/collections/blog_post/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
        sort_by: [
          {
            field: "order",
            order: "asc",
          },
        ],
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.SingleBlog = [];
            return;
          } else if (data.data.length == 0) {
            this.SingleBlog = [];
            return;
          }
          this.SingleBlog = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.blogContent {
  padding: 120px 30px 30px 30px;
}
.blog-image {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}
img.serviceimage {
  width: 100%;
  height: 85%;
}
.blogs-container {
  max-width: 700px;
}
.blog-content {
  font-size: 18px;
}
@media (max-width: 998px) and (min-width: 0px) {
  .blogContent {
    padding: 30px;
  }
  .social-share {
    flex-direction: column;
    gap: 15px;
  }
  .blogtags {
    margin-top: 20px;
  }
}
.readtime {
  padding: 0px 5px;
}
span.dot {
  position: relative;
  bottom: 5px;
  left: 3px;
}
.blogssocial-links a {
  padding: 10px 10px 10px 0px;
}
.blogtags {
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #dadcdf;
  border-radius: 10px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
}
.social-share {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.sub-title {
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: #5f6368;
}
.v-items-center	{
  align-items: center;
}
.vicon	{
  fill: gray;
}
.vicon:hover {
  fill: #000 !important;
}
</style>
